import React from "react";
import "./style.css";

export const Desktop = () => {
  return (
    <div className="desktop">
      <div className="frame-wrapper">
        <div className="frame">
          <div className="text-wrapper">IDOI</div>
          <div className="div">club</div>
          <p className="work-in-progress-let">
            Work in progress. Let&#39;s play a game until then. Assuming you&#39;re not bald, reach up and rip out
            exactly two pieces of hair. Next, twist them together. Congratulations: you now have an exact representation
            of what I am trying to do. Also, you&#39;re one step closer to baldness. Checkmate.
          </p>
        </div>
      </div>
    </div>
  );
};
